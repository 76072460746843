import { openPopupWidget } from "react-calendly";

const url = "https://calendly.com/corina-wingravity";

const pageSettings = {
  backgroundColor: "ffffff",
  hideEventTypeDetails: false,
  hideLandingPageDetails: false,
  primaryColor: "00a2ff",
  textColor: "4d5055",
  hideGdprBanner: true,
};

const makeSchedule = (source) => {
  openPopupWidget({
    url: url,
    pageSettings: pageSettings,
    prefill: {
      email: "",
      firstName: "First",
      lastName: "Last",
      customAnswers: {
        a1: source,
      },
    },
  });
};

export default makeSchedule;
