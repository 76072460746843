import React from "react";
import { Helmet } from "react-helmet";

const Seo = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>TechnologyInterpreter™</title>
    <link rel="icon" href="favicon.png" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    {/* Bots */}
    <meta name="robots" content="index, follow" />
    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
    {/* App /> */}
    <meta name="description" content="My name is Corina Craescu. I am the Technology Interpreter™ and the Co-Founder of Wingravity.com and Slashscore.ai." />
    <meta name="theme-color" content="#008f68" />
    <meta
      name="keywords"
      content="techcareers, techrecruitment, softwareindustry, softwaredevelopment, softwareengineering, womenintech, womeninbusiness, womeninstem, entrepreneur, technology, womenempowerment, girlswhocode, womenwhocode, startup, coding, startuplife, stem, women, entrepreneurship, femalefounders, girlsintech, womensupportingwomen, womeninbiz, womenentrepreneurs, womenintechnology, programming"
    />
    <meta name="author" content="The Technology Interpreter™" />
    {/* Open Graph data */}
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="The Technology Interpreter™ | Creating history, not repeating it." />
    <meta
      property="og:description"
      content='As a business owner, how many times have you found yourself thinking “It’s all Greek to me!" whenever discussing with a designer, a software engineer, or any service provider in general? This is where I, the Technology Interpreter™, come into play. Because I’m able to understand the languages of business owners, designers and software engineers, I can easily translate them all into a language that can help your software project succeed.'
    />

    <meta property="og:url" content="https://www.technologyinterpreter.com/" />
    <meta property="og:site_name" content="The Technology Interpreter™" />
    <meta property="og:image" content="https://www.technologyinterpreter.com/technology-interpreter-landing.png" />
    <meta property="og:image:width" content="2401" />
    <meta property="og:image:height" content="1261" />
    {/* Twitter Card data */}
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@corina_craescu" />
    <meta name="twitter:title" content="The Technology Interpreter™| Creating history, not repeating it." />
    <meta
      name="twitter:description"
      content="Schedule a 15-minutes free business consultation to discuss your hiring needs and/or software necessities, to understand how I can be helpful and what are the next steps to future cooperation."
    />
    <meta name="twitter:creator" content="@corina_craescu" />
    {/* Facebook Card data */}
    <meta name="facebook:card" content="summary" />
    <meta name="facebook:site" content="@technologyinterpreter" />
    <meta name="facebook:title" content="The Technology Interpreter™ | Creating history, not repeating it." />
    <meta
      name="facebook:description"
      content="Join the only platform that uses AI and 10+ integrations to measure the performance of top software engineers."
    />
    <meta name="facebook:creator" content="@slashcoreai" />
    {/* Linkedin Card data */}
    <meta name="linkedin:card" content="summary" />
    <meta name="linkedin:site" content="@slashcoreai" />
    <meta name="linkedin:title" content="Slashscore|The Resume of the Future for Top Software Developers" />
    <meta
      name="linkedin:description"
      content="Schedule a 15-minutes free business consultation to discuss your hiring needs and/or software necessities, to understand how I can be helpful and what are the next steps to future cooperation."
    />
    <meta name="linkedin:creator" content="@slashcoreai" />
    {/* Rights */}
    <meta name="dcterms.rightsHolder" content="WIN GRAVITY SRL" />
    <meta name="dcterms.rights" content="Statement of copyright" />
    <meta name="dcterms.dateCopyrighted" content="2021" />
    <meta name="copyright" content="WIN GRAVITY SRL" />
    <link rel="canonical" href="https://www.technologyinterpreter.com" />
  </Helmet>
);

export default Seo;
