import React, { useState } from "react";

import * as style from "../styles/main.module.css";
import linkedInLogo from "../images/linkedin-brands.svg";
import heroImage from "../images/corina.png";
import heroImageSecondary from "../images/notebook-design.png";
import diamondDesign from "../images/diamond-design.png";
import circleDesign from "../images/circle-design.png";
import arrowBtnTurquoise from "../images/schedule-meeting-left.png";
import winLogo from "../images/Wingravity.png";
import SlashLogo from "../images/Slashscore.png";
import arrowBtnYellow from "../images/schedule-meeting-right.png";

import makeSchedule from "../lib/CalendlyIntegration";
import { isMobile } from "../lib/responsive";

const HeroTitle = (props) => {
  const { as: As = "h1", className } = props;

  return (
    <div className={className}>
      <As>
        The Technology <br />
        <span className={style.titlePartOne}>Interp</span>
        <span className={style.titlePartTwo}>reter™</span>
      </As>
    </div>
  );
};

const Main = () => {
  const [side, setSide] = useState(0);

  const updateSide = (side) => {
    if (isMobile()) {
      return;
    }
    setSide(side);
  };

  return (
    <div className={style.heroContainer}>
      {/* Left Section */}
      <div
        role="button"
        tabIndex={0}
        onMouseLeave={() => updateSide("")}
        onMouseEnter={() => updateSide("left")}
        className={`${style.heroContainerSection} ${style.heroContainerSectionLeft} ${side === "right" ? style.inactive : side === "left" ? style.active : ""}`}
      >
        {/* LOGO */}
        <div className={style.logo}>
          <a href="https://www.wingravity.com/" target="_blank" rel="noreferrer">
            <img src={winLogo} width="200px" alt="Wingravity Logo" />
          </a>
        </div>
        {/* CONTENT */}
        <div className={style.heroContainerSectionContent}>
          <HeroTitle as="h2" className={style.heroSubtitle} />
          <div>
            <div className={style.contentH3}>
              <h3>Interested in software development services?</h3>
            </div>
            <div
              tabIndex={0}
              role={"button"}
              className={`${style.ctaBtn}`}
              onKeyPress={(e) => (e.code === "Enter" ? makeSchedule("Meeting for Development Services") : null)}
              onClick={() => makeSchedule("Meeting for Development Services")}
            >
              <button className={style.btnLeft}>
                <span className={style.btnText}>
                  <strong>SCHEDULE A MEETING</strong>
                </span>
              </button>
              <img className={style.arrowBtn} src={arrowBtnTurquoise} width="75" alt="Button Arrow" />
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div
        role="button"
        tabIndex={0}
        onMouseLeave={() => updateSide("")}
        onMouseEnter={() => updateSide("right")}
        className={`${style.heroContainerSection} ${style.heroContainerSectionRight} ${
          side === "left" ? style.inactive : side === "right" ? style.active : ""
        }`}
      >
        {/* LOGO */}
        <div className={style.logo}>
          <a href="https://www.slashscore.ai/" target="_blank" rel="noreferrer">
            <img src={SlashLogo} width="200px" alt="Slashscore Logo" />
          </a>
        </div>
        {/* CONTENT */}
        <div className={style.heroContainerSectionContent}>
          <HeroTitle as="h2" className={style.heroSubtitle} />
          <div>
            <div className={style.contentH3}>
              <h3>Interested in finding more about Slashscore?</h3>
            </div>
            <div
              tabIndex={0}
              role={"button"}
              className={style.ctaBtn}
              onKeyPress={(e) => (e.code === "Enter" ? makeSchedule("Find more about Slashscore") : null)}
              onClick={() => makeSchedule("Find more about Slashscore")}
            >
              <button className={style.btnRight}>
                <span className={`${style.btnText} ${style.btnTextRight}`}>
                  <strong>SCHEDULE A MEETING</strong>
                </span>
              </button>
              <img src={arrowBtnYellow} width="75" alt="Slashscore logo" />
            </div>
          </div>
          <a className={style.contact} href={"https://ro.linkedin.com/in/corinaberigoi"} target="_blank" rel="noreferrer">
            <div className={style.nameContainer}>
              <img className={style.linkedinLogo} src={linkedInLogo} alt="LinkedIn logo" />
              <strong className={style.name}>Corina Craescu</strong>
            </div>
            <span className={style.corinaCompanyPosition}>Co-Founder Wingravity & Slashscore</span>
          </a>
        </div>
      </div>

      {/* Title */}
      <HeroTitle className={style.heroTitle} />

      {/* Images */}
      <div className={style.heroContainerImages}>
        <img className={style.imageSpace} src={heroImageSecondary} alt="Wingravity" />
        <img className={style.imageFounder} src={heroImage} alt="Wingravity Co-Founder" />
        <img className={style.imageDiamond} src={diamondDesign} alt="Slashscore Diamond" />
        <img className={style.imageCircle} src={circleDesign} alt="Slashscore Circle" />
      </div>
    </div>
  );
};

export default Main;
