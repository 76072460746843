import React from "react";
import Main from "../components/Main";
import Seo from "../components/Meta";

import "../styles/global.css";

// markup
const IndexPage = () => {
  return (
    <main>
      <Seo />
      <Main />
    </main>
  );
};

export default IndexPage;
