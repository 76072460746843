// extracted by mini-css-extract-plugin
export var heroContainer = "main-module--hero-container--aXezf";
export var contact = "main-module--contact--2w_fV";
export var linkedinLogo = "main-module--linkedin-logo--1fl-D";
export var nameContainer = "main-module--name-container--2j2zb";
export var name = "main-module--name--vN1Ja";
export var corinaCompanyPosition = "main-module--corina-company-position--1Db_1";
export var heroContainerSection = "main-module--hero-container-section--2Xng2";
export var heroContainerSectionLeft = "main-module--hero-container-section-left--3hTou";
export var heroContainerSectionRight = "main-module--hero-container-section-right--6bkwp";
export var heroContainerSectionContent = "main-module--hero-container-section-content--1GcOM";
export var inactive = "main-module--inactive--ViBSX";
export var ctaBtn = "main-module--cta-btn--3nD4L";
export var heroTitle = "main-module--hero-title--2HC1B";
export var heroSubtitle = "main-module--hero-subtitle--kG_Eh";
export var active = "main-module--active--zHttb";
export var titlePartOne = "main-module--title-part-one--jx4z8";
export var titlePartTwo = "main-module--title-part-two--1iXAH";
export var heroContainerImages = "main-module--hero-container-images--1OcZ2";
export var imageSpace = "main-module--image-space--1HAtV";
export var imageFounder = "main-module--image-founder--UDPd0";
export var imageDiamond = "main-module--image-diamond--hcI1J";
export var imageCircle = "main-module--image-circle--2wXLg";
export var btnText = "main-module--btn-text--2Hzos";
export var logo = "main-module--logo--C8-Z6";
export var btnLeft = "main-module--btn-left--1oVdA";
export var btnRight = "main-module--btn-right--1VPxi";
export var contentH3 = "main-module--content-h3--hCTGe";
export var btnTextRight = "main-module--btn-text-right--3E31i";
export var arrowBtn = "main-module--arrow-btn--2lgQ3";